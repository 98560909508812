import React from "react"
import { Link } from "react-router-dom"
import "../styles/Header.css"

const Header = () => {
  return (
    <header>
      <div className="logo">
        <img src="/images/logo.png" alt="Logo" />
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          {/* <li>
            <Link to="/about">About</Link>
          </li> */}
        </ul>
      </nav>
    </header>
  )
}

export default Header
