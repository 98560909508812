import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobileAlt,
  faCogs,
  faToolbox,
  faBuilding,
  faShoppingCart, // New icon for the additional service
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  // Array of service objects, each containing a name, description, and icon
  const services = [
    {
      name: "Static Website Designing",
      description:
        "We create visually appealing static websites that are optimized for performance and SEO.",
      icon: faDesktop,
    },
    {
      name: "Responsive Web Design",
      description:
        "Our designs ensure your website looks great on all devices, providing a seamless user experience.",
      icon: faMobileAlt,
    },
    {
      name: "Custom Web Design",
      description:
        "Tailored web designs to fit your unique business needs and branding, creating a distinct online presence.",
      icon: faCogs,
    },
    {
      name: "Website Maintenance",
      description:
        "We offer reliable maintenance services to keep your website up-to-date and running smoothly.",
      icon: faToolbox,
    },
    {
      name: "Corporate Web Design",
      description:
        "Professional web designs aimed at enhancing corporate branding and client engagement.",
      icon: faBuilding,
    },
    {
      name: "E-commerce Development", // New service
      description:
        "We develop scalable and secure e-commerce platforms that help drive sales and enhance customer experience.",
      icon: faShoppingCart, // New icon
    },
  ];

  // Inline styles for the card container, individual cards, and technology highlights
  const styles = {
    container: {
      padding: "20px",
      maxWidth: "1000px", // Increased maxWidth to reduce left and right margin
      margin: "0 auto",
      textAlign: "center",
    },
    techContainer: {
      marginBottom: "30px", // Reduced margin-bottom for tighter layout
    },
    techTitle: {
      fontSize: "1.5em",
      fontWeight: "bold",
      marginBottom: "15px", // Reduced margin-bottom for tighter layout
    },
    techText: {
      textAlign: "left",
      marginBottom: "8px", // Reduced margin-bottom for tighter layout
      fontSize: "1em",
    },
    techHighlight: {
      fontWeight: "bold",
      color: "#5E239D", // Brinjal color for highlights
    },
    techTag: {
      backgroundColor: "#5E239D", // Brinjal color for background
      color: "white", // White text color for contrast
      padding: "5px 10px",
      borderRadius: "5px",
      margin: "5px",
      display: "inline-block",
      fontSize: "0.9em",
    },
    cardContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between", // Adjusted for even spacing
      gap: "20px",
    },
    card: {
      backgroundColor: "#5E239D", // Brinjal color for card background
      color: "white", // White text color for contrast
      borderRadius: "8px",
      padding: "20px",
      width: "calc(33% - 80px)", // Adjusted width for three cards per row
      textAlign: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "10px 0", // Reduced margin for tighter layout
    },
    cardHeader: {
      fontSize: "1.2em",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    cardDescription: {
      fontSize: "0.9em",
      lineHeight: "1.4em",
    },
    icon: {
      fontSize: "2em",
      marginBottom: "10px",
    },
  };

  return (
    <div style={styles.container}>
      {/* Section for technology explanation */}
      <div style={styles.techContainer}>
        <h1 style={styles.techTitle}>Technologies We Use</h1>
        <p style={styles.techText}>
          We use modern technologies to build robust web applications, ensuring high performance and great user experience.
        </p>
        {/* Technology tags section */}
        <div>
          <span style={styles.techTag}>Ruby on Rails</span>
          <span style={styles.techTag}>React</span>
          <span style={styles.techTag}>Angular</span>
          <span style={styles.techTag}>Remix</span>
          <span style={styles.techTag}>CSS</span>
          <span style={styles.techTag}>HTML</span>
          <span style={styles.techTag}>JavaScript</span>
          <span style={styles.techTag}>Python</span>
        </div>
      </div>

      {/* Service cards section */}
      <h1>Our Services</h1>
      <div style={styles.cardContainer}>
        {/* Mapping through services to create a card for each service */}
        {services.map((service, index) => (
          <div key={index} style={styles.card}>
            {/* Icon for the service */}
            <FontAwesomeIcon icon={service.icon} style={styles.icon} />
            {/* Service name */}
            <div style={styles.cardHeader}>{service.name}</div>
            {/* Service description */}
            <p style={styles.cardDescription}>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
