import React from "react"
import { useLottie } from "lottie-react"
import animationData from "../assets/HomeAnimation.json"

const Home = () => {
  const options = {
    animationData: animationData,
    loop: true,
  }

  const { View } = useLottie(options)

  return (
    <main className="home">
      <div className="heading-container">
        <div className="animation-container">{View}</div>
        <div className="heading-design">
          <h1>Empowering Your Vision Through Design and Development</h1>
          <p>
            At DesignDevWorld, we turn your ideas into captivating digital
            experiences.
          </p>
          <p>
            Our innovative designs and seamless development processes ensure
            top-notch quality and functionality.
          </p>
          <p>
            With a focus on user-centered design, we create solutions that not
            only look great but also enhance user engagement
          </p>
          <p>
            Let us help you bring your vision to life with creativity and
            cutting-edge technology.
          </p>
        </div>
      </div>
      <div className="service-container-1">
        <div className="service">
          <h3>Web App Development</h3>
          <p>
            We specialize in creating robust, scalable web applications that are
            tailored to your business needs. Our solutions are user-friendly and
            built to scale seamlessly as your business grows.
          </p>
        </div>
        <div className="service">
          <h3>Mobile App Development</h3>
          <p>
            Our team develops high-performing mobile applications for both
            Android and iOS platforms. Whether you need a native app or a hybrid
            solution, we deliver apps that provide a seamless user experience.
          </p>
        </div>
      </div>
      <div className="service-container-2">
        <div className="service">
          <h3>UI/UX Design</h3>
          <p>
            We craft intuitive and visually appealing designs that enhance user
            engagement and satisfaction. Our design process is focused on
            understanding user behavior and creating interfaces that are both
            beautiful and functional.
          </p>
        </div>
        <div className="service">
          <h3>Frontend Development</h3>
          <p>
            Our expertise in frontend technologies ensures that your application
            not only looks great but also performs efficiently. We integrate
            with your backend systems to create cohesive and responsive
            interfaces.
          </p>
        </div>
      </div>
    </main>
  )
}

export default Home
