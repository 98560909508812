import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import "../styles/Footer.css"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <section className="footer-section">
          <img src="/images/logo.png" alt="Logo" className="footer-logo" />
          <p>© {currentYear} All rights reserved.</p>
        </section>

        <section className="footer-section">
          <h1 className="footer-heading">Services</h1>
          <p>
            <a href="/services">Design</a>
          </p>
          <p>
            <a href="/services">Technology</a>
          </p>
          <p>
            <a href="/services">Digital Marketing</a>
          </p>
        </section>

        <section className="footer-section">
          <h1 className="footer-heading">Quick Links</h1>
          <p>
            <a href="/work">Work</a>
          </p>
          <p>
            <a href="/clients">Clients</a>
          </p>
          <p>
            <a href="/blog">Blog</a>
          </p>
          <p>
            <a href="/contact">Reach Us</a>
          </p>
        </section>

        <section className="footer-section">
          <h1 className="footer-heading">Contact Us</h1>
          <p>
            Email:{" "}
            <a href="mailto:designdevworld@gmail.com">
              designdevworld@gmail.com
            </a>
          </p>
          <p>
            Phone: <a href="tel:+919571301282">+91 9571301282</a>
          </p>
          <p>Follow us on:</p>
          <p className="footer-social-links">
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link">
              <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link">
              <FontAwesomeIcon icon={faFacebook} /> Facebook
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link">
              <FontAwesomeIcon icon={faInstagram} /> Instagram
            </a>
          </p>
        </section>
      </div>
    </footer>
  )
}

export default Footer
